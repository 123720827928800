  <template>
      <v-container fluid>
        <customer-report-title :title="options.title" class="mb-2" />
        <customer-report-sub-category :tabs="options.tabs" @select-tab="selectTab" />
        <customer-report-filter
          :organizations="options.organizations"
          @load-report="fetchReport($event)"
          @export-table="exportTableToXLSX($event)"
        />
        <customer-report-kpi-bar />
        <customer-report-table :headers="options.headers" :items="options.data" />
        <customer-report-alert />
        <v-snackbar v-model="snackbar" :top="true" :timeout="-1">
          <v-card>
              <v-card-title>
                  <span class="headline">{{ snackbarMessage }}</span>
              </v-card-title>
              <v-card-text>
                  {{ snackbarErrorMessage }}
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn dark text @click="snackbar = false">
                      Закрыть
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-snackbar>
      </v-container>
    </template>
    
    <script>
    import { Vue } from "vue-property-decorator";
    import { get, sync } from "vuex-pathify";
    import { format, startOfYear } from "date-fns";
    import { mapActions, mapState } from "vuex";
    import { json2xlsx } from "@/util/helpers";
    
    export default Vue.extend({
      name: "ReportTransport",
      components: {
        CustomerReportTitle: () => import("@/components/report/customerreport/CustomerReportTitle.vue"),
        CustomerReportChart: () => import("@/components/report/customerreport/CustomerReportChart.vue"),
        CustomerReportSubCategory: () =>
          import("@/components/report/customerreport/CustomerReportSubCategory.vue"),
        CustomerReportFilter: () => import("@/components/report/customerreport/CustomerReportFilter.vue"),
        CustomerReportKpiBar: () => import("@/components/report/customerreport/CustomerReportKPIBar.vue"),
        CustomerReportTable: () => import("@/components/report/customerreport/CustomerReportTable.vue"),
        CustomerReportAlert: () => import("@/components/report/customerreport/CustomerReportAlert.vue")
      },
      computed: {
        options: get("report/optionsRefinery"),
        ...sync("report", ["currentTab"]),
        errorMessage: get("report/errorMessages@export"),
        ...mapState({ series: state => state.report.series }),
        ...mapState({ chartOptions: state => state.report.chartOptions })
      },
      data() {
        return {
          json2xlsx,
          snackbar: false,
          snackbarErrorMessage: '',
          snackbarMessage: '',
        };
      },
      methods: {
        ...mapActions({
          resetReport: "report/resetRefineryReport",
          // fetchOrganizations: "report/fetchRefineryOrganizations",
          fetchReport: "report/fetchRefineryData"
        }),
        initialize() {
          this.currentTab = this.options.tabs[0];
          this.selectTab(this.currentTab);
        },
        selectTab(tab) {
          const link = tab.link.toLowerCase();
          // this.fetchOrganizations(link);
          this.resetReport(tab);
          this.showDefaultChart(link);
        },
        showDefaultChart(link) {
          const dateFrom = format(startOfYear(Date.now()), "yyyy-MM-dd");
          const dateTo = format(Date.now(), "yyyy-MM-dd");
          const organizationId = localStorage.getItem("organizationId");
          const options = {
            path: link,
            params: {
              organizationId: organizationId,
              date_from: dateFrom,
              date_to: dateTo
            }
          };
          const response = this.fetchReport(options);
          if (response.data.length === 0) {
            this.snackbar = true;
            this.snackbarErrorMessage = 'NONE';
            this.snackbarMessage = 'NONE';
          } else {
            this.snackbar = false;
          }
        },
        exportTableToXLSX(currentTab) {
          this.options.data.length > 0
            ? json2xlsx({
                data: this.options.data,
                header: [this.options.headers.map(header => header.text)],
                sheetName: "Отчет",
                fileName:
                  "ИСУНГ - " + this.options.title + " - " + currentTab.name + ".xlsx"
              })
            : console.log(this.errorMessage);
        }
      },
      async mounted() {
        this.initialize();
      }
    });
    </script>
    